<div class="terminal">
  <div class="text">
    {{content}}
    <ng-container *ngFor="let container of containers">
      <ng-container *ngIf="container.command !== 'clear'" [ngTemplateOutlet]="readOnlyCommandLine"
                    [ngTemplateOutletContext]="{context:container.command}"></ng-container>
      <ng-container *ngIf="container.command === 'help'" [ngTemplateOutlet]="help"></ng-container>
      <ng-container *ngIf="container.command === 'about'" [ngTemplateOutlet]="about"></ng-container>
      <ng-container *ngIf="container.command === 'social'" [ngTemplateOutlet]="social"></ng-container>
      <ng-container *ngIf="['help', 'about', 'social', 'clear'].indexOf(container.command) === -1"
                    [ngTemplateOutlet]="notfound"
                    [ngTemplateOutletContext]="{context:container.command}"></ng-container>
    </ng-container>
  </div>
  <ng-container *ngTemplateOutlet="commandLine"></ng-container>
</div>

<ng-template #help>
  <div class="template help">
    Usages:
    <p>about</p>
    <p>social</p>
    <p>clear</p>
  </div>
</ng-template>

<ng-template #about>
  <div class="template about">
    Yusuf Doğan - Software Engineer
  </div>
</ng-template>

<ng-template #social>
  <div class="template social">
    <a href="https://tr.linkedin.com/in/yusufdgn" class="social-link" target="_blank">LinkedIn</a>
    <a href="https://instagram.com/yusufdgn" class="social-link" target="_blank">Instagram</a>
    <a href="https://twitter.com/01011001D" class="social-link" target="_blank">Twitter</a>
  </div>
</ng-template>

<ng-template #notfound let-context="context">
  <div class="template other">
    Undefined command : ({{context}})<br>
    For list of usages type "help"
  </div>
</ng-template>

<ng-template #commandLine>
  <div class="prompt">
    <span class="user">{{username}}</span><span class="location">~</span><span class="dollar">$</span>
    <div class="command">
      <input type="text" class="line" id="cLine" #cLine (keydown.enter)="process($event)" (blur)="onBlur()" autofocus>
    </div>
  </div>
</ng-template>

<ng-template #readOnlyCommandLine let-context="context">
  <div class="prompt">
    <span class="user">{{username}}</span><span class="location">~</span><span class="dollar">$</span>
    <div class="command">
      <input type="text" class="line" value="{{context}}" readonly>
    </div>
  </div>
</ng-template>
